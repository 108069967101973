import React from "react" 
import ImageMeta from "../components/ImageMeta"
import NuvoImage from "../components/NuvoImage"
import { graphql, Link } from "gatsby"
import { sortArchive, getFeaturedProceduresAndOtherProceduresObject } from "../utils/utils"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"
import PropTypes from "prop-types"
import SEO from "../components/seo"

import TheaterVideo from "../components/Theater/TheaterVideo"
import Button from '@bit/azheng.joshua-tree.button';

function createMarkup(html) {
  return { __html: html }
}
function ProcedureHeading(html) {
  return <h6 dangerouslySetInnerHTML={createMarkup(html)}></h6>
}
function ProcedureParagraph(html, className) {
  return (
    <p className={className} dangerouslySetInnerHTML={createMarkup(html)}></p>
  )
}

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

function ProceduresRow(props) {
  let procedures = props.procedures
  let itemNumber = props.itemNumber
  let rowClass = (props.rowClass ? props.rowClass : "")


  if (procedures && itemNumber === 2) {
    return (
      <div className={`columns procedure-row ${rowClass}`}>
        <div className="column is-2"></div>
        <div className="column">
          <Link to={"/" + procedures[0].title+"/"}>
            <NuvoImage
              useAR
              cloudName="nuvolum"
              publicId={procedures[0].thumbnailPublicId}
              responsive
              crop="scale"
              width="auto"
            >
              
            </NuvoImage>
          </Link>
          {ProcedureHeading(procedures[0].name)}
          {ProcedureParagraph(procedures[0].blurb, "footer-p")}
        </div>
        <div className="column is-2"></div>
        <div className="column">
          {procedures[1] && (
            <>
              <Link to={"/" + procedures[1].title+"/"}>
                <NuvoImage
                  useAR
                  cloudName="nuvolum"
                  publicId={procedures[1].thumbnailPublicId}
                  responsive
                  width="auto"
                >
                  
                </NuvoImage>
              </Link>
              {ProcedureHeading(procedures[1].name)}
              {ProcedureParagraph(procedures[1].blurb, "footer-p")}
            </>
          )}
        </div>
        <div className="column is-2"></div>
      </div>
    )
  } else if (procedures && itemNumber === 3) {
    return (
      <div className={`columns procedure-row ${rowClass}`}>
        <div className="column is-2"></div>
        <div className="column">
          <Link to={"/" + procedures[0].title+"/"}>
            <ImageMeta
              cloudName="nuvolum"
              publicId="denali/procedure-dental-implant.png"
              responsive
              width="auto"
            >
              
            </ImageMeta>
          </Link>
          {ProcedureHeading(procedures[0].name)}
          {ProcedureParagraph(procedures[0].blurb, "footer-p")}
        </div>
        <div className="column is-1"></div>
        <div className="column">
          {procedures[1] && (
            <>
              <Link to={"/" + procedures[1].title+"/"}>
                <ImageMeta
                  cloudName="nuvolum"
                  publicId="denali/procedure-dental-implant.png"
                  responsive
                  width="auto"
                >
                  
                </ImageMeta>
              </Link>
              {ProcedureHeading(procedures[1].name)}
              {ProcedureParagraph(procedures[1].blurb, "footer-p")}
            </>
          )}
        </div>
        <div className="column is-1"></div>
        <div className="column">
          {procedures[2] && (
            <>
              <Link to={"/" + procedures[2].title+"/"}>
                <ImageMeta
                  cloudName="nuvolum"
                  publicId="denali/procedure-dental-implant.png"
                  responsive
                  width="auto"
                >
                  
                </ImageMeta>
              </Link>
              {ProcedureHeading(procedures[2].name)}
              {ProcedureParagraph(procedures[2].blurb, "footer-p")}
            </>
          )}
        </div>
        <div className="column is-2"></div>
      </div>
    )
  } else {
    return;
  }
}

function AdditionalProcedures(props) {
  let procedures = props.procedures
  let leftHalf = []
  let rightHalf = []

  for (let i = 0; i < procedures.length; i++) {
    if (i % 2 === 0) {
      leftHalf.push(procedures[i])
    } else if (i % 2 === 1) {
      rightHalf.push(procedures[i])
    }
  }
  return (
    <div className="additional-procedures">
      <div className="columns">
        <div className="column is-2"></div>
        <div className="column">
          <h5 style={{ marginBottom: "1.125rem" }}>Additional Procedures</h5>
        </div>
      </div>
      <div className="columns">
        <div className="column is-2"></div>
        <div className="column">
          <div className="columns">
            <div className="column additional-procedure-column">
              {leftHalf.map(procedure => (
                <>
                  <div className="additional-procedure-item">
                    <Link className="hover-link" to={"/" + procedure.title+"/"}>
                      {ProcedureHeading(procedure.name)}
                    </Link>
                    {ProcedureParagraph(procedure.blurb, "footer-p")}
                  </div>
                </>
              ))}
            </div>
            <div className="column is-2"></div>
            <div className="column additional-procedure-column">
              {rightHalf.map(procedure => (
                <>
                  <div className="additional-procedure-item">
                    <Link className="hover-link" to={"/" + procedure.title+"/"}>
                      {ProcedureHeading(procedure.name)}
                    </Link>
                    {ProcedureParagraph(procedure.blurb, "footer-p")}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="column is-2"></div>
      </div>
    </div>
  )
}

AdditionalProcedures.propTypes = {
  procedures: PropTypes.array,
}

const ProceduresQueryPage = ({ data }) => {

  const post = data.allUniquePagesJson.nodes[0];
  var featuredProcedures = [];
  // var regularProceduresFirstRow = []
  // var regularProceduresSecondRow = []

  var allProcedures = data.allProceduresJson.nodes;


  function sortFeaturedProceduresAndGetAll(procedures) {
    var featuredAndOtherProcedures = getFeaturedProceduresAndOtherProceduresObject(procedures)

    let featuredProcedures = featuredAndOtherProcedures.featuredProcedures;
    let otherProcedures = featuredAndOtherProcedures.otherProcedures;

    sortArchive(featuredProcedures)
    allProcedures = featuredProcedures.concat(otherProcedures)
    return allProcedures;
  }

  allProcedures = sortFeaturedProceduresAndGetAll(allProcedures)
 

  var additionalProcedures = [];
  var procedurePair = [];

  for (let i = 0; i < allProcedures.length; i++) {
    let procedure = allProcedures[i]

    if (procedure.procedureLevel) {
      if (procedure.procedureLevel === "featured" && procedurePair.length <= 1) {
        
        procedurePair.push(procedure)
        if (procedurePair.length === 2) {
          featuredProcedures.push(procedurePair);
          procedurePair = []
        }
      }
        else {
          additionalProcedures.push(procedure)
        }
    }
  }

  if (procedurePair.length === 1) {
    additionalProcedures.push(procedurePair[0])
  }


  var featuredProceduresRows = featuredProcedures.map( (row, i) => {
    if (i === featuredProcedures.length - 1) {
      var rowClass = "last"
    }
    return (
      <ProceduresRow key={i} procedures={row} rowClass={rowClass} itemNumber={row.length} />
    )
  })

  return (
    <SharedStateProvider>
      <Layout>
  <SEO title={post.metaTitle} description={post.metaDescription} schemaPage={post.schemaPage}/>
        <div className="joshua-tree-content">
          <div className="columns top-section text-section white-back">
            <div className="column">
              <div className="columns is-mobile">
                <div className="column is-5"></div>
                <div className="column">
                  <div>
                    <h1>{post.heading}</h1>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-4"></div>
                <div className="column is-16">
                  <p dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.topBlurb)
                  )}></p>
                </div>
                <div className="column is-4"></div>
              </div>
            </div>
          </div>
          <div className="body-section">
            {featuredProceduresRows}
            {/* <div className="columns parallax-procedure">
              <div className="column is-full-width">
                <ImageMeta
                  cloudName="nuvolum"
                  publicId="FOMS/wisdom-teeth-banner-background.jpg"
                  
                  width="auto"
                >
                  
                </ImageMeta>
              </div>
            </div> */}
            <AdditionalProcedures procedures={additionalProcedures} />
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

ProceduresQueryPage.propTypes = {
  data: PropTypes.shape({
    allProceduresJson: PropTypes.shape({
      nodes: PropTypes.shape({
        title: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        blurb: PropTypes.string.isRequired,
        procedureLevel: PropTypes.string.isRequired,
        length: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query proceduresQueryPageEs ($loneUrl: String!)  {
    allUniquePagesJson(filter: {title: {eq: $loneUrl}}) {
      nodes {
        title
        metaTitle
        metaDescription
        heading
        topBlurb
        schemaPage
      }
    }
    allProceduresJson {
      nodes {
        name
        blurb
        procedureLevel
        title
        order
        thumbnailPublicId
    }
  }
}
`

export default ProceduresQueryPage
